import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <section
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Spinner
        animation="border"
        variant="primary"
        as="span"
        size="lg"
        role="status"
        aria-hidden="true"
      />
    </section>
  );
};

export default Loading;
