import React, { useState, useEffect, createContext } from "react";
import Loading from "../Components/Loading"; 

const LanguageContext = createContext({
  language: "es",
  changeLanguage: () => {},
  translations: {}, 
});

const LanguageProvider = ({ children }) => {
  const storedLanguage = localStorage.getItem("language") || "es";
  
  const [language, setLanguage] = useState(storedLanguage);
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadTranslations = async () => {
      setLoading(true);
      try {
        const translationModule = await import(
          `../locales/${language}/translation.json`
        );
        setTranslations(translationModule); 
        setError(null);
      } catch (error) {
        console.error("Error loading translations:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadTranslations();
  }, [language]);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
    document.querySelector("html").setAttribute("lang", newLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, translations }}
    >
      {loading || error ? <Loading /> : children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
