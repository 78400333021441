import React, { lazy, Suspense, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ContentDataProvider } from "./context/ContentDataContext";
import { LanguageProvider, LanguageContext } from "./context/LanguageContext";
import Loading from "./Components/Loading";
import "./App.css";

const Home = lazy(() => import("./Pages/Home"));
const Contact = lazy(() => import("./Pages/Contact"));
const FormJob = lazy(() => import("./Pages/FormJob"));
const FormService = lazy(() => import("./Pages/FormService"));
const NotFound = lazy(() => import("./Pages/NotFound"));
const BlogMain = lazy(() => import("./Pages/BlogMain"));
const Us = lazy(() => import("./Pages/Us"));
const WellnessMain = lazy(() => import("./Pages/WellnessMain"));
const Rpa = lazy(() => import("./Pages/Process/RPA"));
const IA = lazy(() => import("./Pages/Process/IA"));
const CRM = lazy(() => import("./Pages/Process/CRM"));
const API = lazy(() => import("./Pages/Process/API"));
const Analitics = lazy(() => import("./Pages/Process/Analytics"));
const MultiAgent = lazy(() => import("./Pages/Omnichannel/MultiAgent"));
const ChatBot = lazy(() => import("./Pages/Omnichannel/ChatBot"));
const CallBot = lazy(() => import("./Pages/Omnichannel/CallBot"));
const ButtonWeb = lazy(() => import("./Pages/Omnichannel/ButtonWeb"));
const CollectionsManagement = lazy(() =>
  import("./Pages/ContactCenter/CollectionsManagement")
);
const DatabaseUpdate = lazy(() =>
  import("./Pages/ContactCenter/DatabaseUpdate")
);
const EventConfirmation = lazy(() =>
  import("./Pages/ContactCenter/EventConfirmation")
);
const LeadsManagement = lazy(() =>
  import("./Pages/ContactCenter/LeadsManagement")
);
const MassiveEmail = lazy(() => import("./Pages/ContactCenter/MassiveEmail"));
const MassiveIVR = lazy(() => import("./Pages/ContactCenter/MassiveIVR"));
const MassiveSMS = lazy(() => import("./Pages/ContactCenter/MassiveSMS"));
const MassiveWhatsApp = lazy(() =>
  import("./Pages/ContactCenter/MassiveWhatsApp")
);
const OmnichannelLicenses = lazy(() =>
  import("./Pages/ContactCenter/OmnichannelLicenses")
);
const OrderTaking = lazy(() => import("./Pages/ContactCenter/OrderTaking"));
const PhoneSurvey = lazy(() => import("./Pages/ContactCenter/PhoneSurvey"));
const PQR = lazy(() => import("./Pages/ContactCenter/PQR"));
const Processes360 = lazy(() => import("./Pages/ContactCenter/Processes360"));
const SpotsRental = lazy(() => import("./Pages/ContactCenter/SpotsRental"));
const TelephoneLicenses = lazy(() =>
  import("./Pages/ContactCenter/TelephoneLicenses")
);
const BlogPostDetail = lazy(() => import("./Pages/BlogPostDetail"));
function App() {
  const { changeLanguage, language } = useContext(LanguageContext);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/es")) {
      changeLanguage("es");
    } else {
      changeLanguage("en");
    }
  }, [location, changeLanguage]);

  return (
    <ContentDataProvider>
      <HelmetProvider>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route
              path="/"
              element={<Navigate to={`/${language}`} replace />}
            />

            <Route path="/es" element={<Home />} />
            <Route path="/en" element={<Home />} />

            <Route path="/es/contacto" element={<Contact />} />
            <Route path="/en/contact" element={<Contact />} />
            <Route
              path="/es/contacto/trabaja-con-nosotros"
              element={<FormJob />}
            />
            <Route path="/en/contact/work-with-us" element={<FormJob />} />

            <Route
              path="/es/contacto/adquirir-servicios"
              element={<FormService />}
            />
            <Route
              path="/en/contact/acquire-services"
              element={<FormService />}
            />

            <Route path="/es/blogs" element={<BlogMain />} />
            <Route path="/en/blogs" element={<BlogMain />} />
            <Route path="/es/blog/:slug" element={<BlogPostDetail />} />
            <Route path="/en/blog/:slug" element={<BlogPostDetail />} />

            <Route path="/es/nosotros" element={<Us />} />
            <Route path="/en/about-us" element={<Us />} />

            <Route path="/es/nosotros/bienestar" element={<WellnessMain />} />
            <Route path="/en/about-us/wellness" element={<WellnessMain />} />

            <Route
              path="/es/servicio/rpa-Automatizacion-Procesos"
              element={<Rpa />}
            />
            <Route
              path="/en/service/rpa-automatization-process"
              element={<Rpa />}
            />

            <Route path="/es/servicio/soluciones-IA" element={<IA />} />
            <Route path="/en/service/solutions-ia" element={<IA />} />

            <Route path="/es/servicio/crm" element={<CRM />} />
            <Route path="/en/service/crm" element={<CRM />} />

            <Route path="/es/servicio/integracion-APIs" element={<API />} />
            <Route path="en/service/integrations-api" element={<API />} />

            <Route
              path="/es/servicio/speech-Text-Analytics"
              element={<Analitics />}
            />
            <Route
              path="/en/service/speech-and-text-analytics"
              element={<Analitics />}
            />

            <Route
              path="/es/servicio/chat-Multiagente"
              element={<MultiAgent />}
            />
            <Route
              path="/en/service/multiagente-chat"
              element={<MultiAgent />}
            />

            <Route path="/es/servicio/chatbot" element={<ChatBot />} />
            <Route path="/en/service/chatbot" element={<ChatBot />} />

            <Route path="/en/service/callbot-ivr" element={<CallBot />} />
            <Route path="/es/servicio/callbot-ivr" element={<CallBot />} />

            <Route path="/es/servicio/boton-web" element={<ButtonWeb />} />
            <Route path="/en/service/web-boton" element={<ButtonWeb />} />

            <Route
              path="/es/servicio/gestion-de-Cobranzas"
              element={<CollectionsManagement />}
            />
            <Route
              path="/en/service/billing-Management"
              element={<CollectionsManagement />}
            />

            <Route
              path="/es/servicio/actualizacion-bases-de-datos"
              element={<DatabaseUpdate />}
            />
            <Route
              path="/en/service/data-bases-updating"
              element={<DatabaseUpdate />}
            />

            <Route
              path="/es/servicio/confirmacion-eventos"
              element={<EventConfirmation />}
            />
            <Route
              path="/en/service/event-confirmation"
              element={<EventConfirmation />}
            />

            <Route
              path="/es/servicio/gestion-de-Leads-y-Ventas"
              element={<LeadsManagement />}
            />
            <Route
              path="/en/service/leads-and-sales-management"
              element={<LeadsManagement />}
            />

            <Route
              path="/es/servicio/masivo-Email"
              element={<MassiveEmail />}
            />
            <Route
              path="/en/service/massives-email"
              element={<MassiveEmail />}
            />

            <Route path="/es/servicio/masivo-IVR" element={<MassiveIVR />} />
            <Route path="/en/service/massives-ivr" element={<MassiveIVR />} />

            <Route path="/es/servicio/masivo-SMS" element={<MassiveSMS />} />
            <Route path="/en/service/massives-sms" element={<MassiveSMS />} />

            <Route
              path="/es/servicio/masivo-Whatsapp"
              element={<MassiveWhatsApp />}
            />
            <Route
              path="/en/service/massives-whatsapp"
              element={<MassiveWhatsApp />}
            />

            <Route
              path="/es/servicio/licencias-omnicanal"
              element={<OmnichannelLicenses />}
            />
            <Route
              path="/en/service/omnichannel-licenses"
              element={<OmnichannelLicenses />}
            />

            <Route
              path="/es/servicio/toma-de-pedidos"
              element={<OrderTaking />}
            />
            <Route
              path="/en/service/order-processing"
              element={<OrderTaking />}
            />

            <Route
              path="/es/servicio/encuestas-Telefonicas"
              element={<PhoneSurvey />}
            />
            <Route
              path="/en/service/surveys-telephone"
              element={<PhoneSurvey />}
            />

            <Route
              path="/es/servicio/servicio-al-cliente-PQR"
              element={<PQR />}
            />
            <Route path="/en/service/pqr-customer-service" element={<PQR />} />
            <Route
              path="/es/servicio/procesos-360"
              element={<Processes360 />}
            />
            <Route
              path="/en/service/360-processes"
              element={<Processes360 />}
            />
            <Route
              path="/es/servicio/alquiler-puestos"
              element={<SpotsRental />}
            />
            <Route path="/en/service/Spots-rental" element={<SpotsRental />} />
            <Route
              path="/es/servicio/licencias-telefonicas"
              element={<TelephoneLicenses />}
            />
            <Route
              path="/en/service/telephone-licenses"
              element={<TelephoneLicenses />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </HelmetProvider>
    </ContentDataProvider>
  );
}

function AppWithLanguageProvider() {
  return (
    <LanguageProvider>
      <Router>
        <App />
      </Router>
    </LanguageProvider>
  );
}

export default AppWithLanguageProvider;
