import React, { createContext,  useState } from "react";

const ContentDataContext = createContext({
  contentData: {
    title: "",
    date: "",
    content: "",
    banner: "",
    tags: "",
  },
  updateContentData: () => {},
});

const ContentDataProvider = ({ children }) => {
  const [contentData, setContentData] = useState({
    title: "",
    date: "",
    content: "",
    banner: "",
    tags: "",
  });

  const updateContentData = (newData) => {
    setContentData(newData);
  };

  return (
    <ContentDataContext.Provider
      value={{
        contentData,
        updateContentData,
      }}
    >
      {children}
    </ContentDataContext.Provider>
  );
};

export { ContentDataContext, ContentDataProvider };
